<template>
<div class="row">
  <div class="colmd-12">
    <h3>{{ step.title }}</h3>
    <p v-if="step.description" class="step-description"></p>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card mb-2" v-for="(item, index) in optionsMapGroup" :key="index">
        <div class="card-body">
          <div class="row d-flex align-items-center">
            <div class="col-md-3 ml-3">
              <div class="card-block">
                <div class="card-title">
                  <h6>
                    <strong>{{ item.name }}</strong>
                    <tooltip v-if="item.tooltip.text || item.tooltip.image" style="display: inline-block" :tooltip="item.tooltip"><i class="fa fa-info-circle text-primary ml-2"></i></tooltip>
                  </h6>
                  <p v-html="item.print_description"></p>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div v-if="item.type === 'check'" class="form-check">
                <input v-if="!item.disabled" v-model="item.selected" type="checkbox" class="form-check-input" :id="`custom-check${item.id}`" @click="checkOption(item)">
                <label class="form-check-label text-danger" :class="{ 'disabled': item.disabled }" :for="`custom-check${item.id}`">
                  <span v-if="item.children.length < 0">{{ $filters.currency(item.price.self) }}</span>
                </label>
              </div>
            </div>
            <div class="col-md-5" :class="{ 'disabled': !item.selected, '': item.selected }">
              <table v-if="item.children.length > 0" class="table table-borderless">
                <tr v-for="(child, idx) in item.children" :key="idx">
                  <td>
                     <h6>
                      <strong>{{ child.name }}</strong>
                      <tooltip v-if="child.tooltip.text || child.tooltip.image" style="display: inline-block" :tooltip="child.tooltip"><span class="fa fa-info-circle text-primary"></span></tooltip>
                    </h6>
                    <p v-html="child.print_description"></p>
                  </td>
                  <td class="text-right">
                    <div v-if="child.type === 'check'" class="form-check">
                      <input v-if="!child.disabled" v-model="child.selected" type="checkbox" class="form-check-input" :id="`custom-check${child.id}`" @click="checkOption(child)">
                      <label class="form-check-label text-danger" :class="{ 'disabled': child.disabled }" :for="`custom-check${child.id}`">{{ $filters.currency(child.price.self) }}</label>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

                <table class="table">
                    <tfoot>
                        <tr>
                            <td><strong>Gesamtmontage</strong></td>
                            <td colspan="2"><span class="text-danger">{{ $filters.currency(total) }}</span></td>
                        </tr>
                        <!--<tr>
                            <td colspan="1">
                                <strong>Bonus für elektrischen Antrieb</strong>
                                <tooltip style="display: inline-block" :tooltip="{ text: 'Dieser Bonus ist nur gültig wenn Sie ein elektrisches Antrieb und eine Komplettmontage kaufen.', image: null }"><span class="fa fa-info-circle text-primary"></span></tooltip>
                            </td>
                            <td>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="custom-check-bonus" @click="checkBonus" v-if="bonusActive">
                                    <label class="custom-control-label text-danger" for="custom-check-bonus" :class="{ 'disabled': !bonusActive, '': bonusActive }">-{{ 300 | currency }}</label>
                                </div>
                            </td>
                            <td>Extra Rabatt von 300€ bei Auswahl von elektrischen Antrieb und eine Komplettmontage.</td>
                        </tr>-->
                    </tfoot>
                </table>
    </div>
  </div>
</div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  setup () {
    const bonus = ref(300)
    const bonusActive = ref(false)
    const type = ref(null)
    const store = useStore()

    const optionsMapGroup = computed(() => {
      const map = {}
      const options = store.getters['configurator/currentStep'].options
      options.forEach(option => {
        if (option.dname === 'children') {
          option.show_for.forEach(o => {
            if (map[o].children.indexOf(option) === -1) {
              map[o].children.push(option)
            }
          })
        } else {
          map[option.id] = option
        }
      })
      return map
    })
    const total = computed(() => {
      let price = 0
      store.getters['configurator/currentStep'].options.filter(o => {
        if (o.selected && !o.disabled) {
          price += o.price.self
        }
      })
      return price
    })
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    // methods
    function checkOption (option) {
      bonusActive.value = false
      store.dispatch('configurator/toggleOption', option)
      const step = store.getters['configurator/currentStep'].options.filter(o => o.selected && o.bonus)
      if (step.length > 0) {
        bonusActive.value = true
      }
    }
    function checkBonus (e) {
      const checked = e.target.checked
      if (checked) {
        store.dispatch('configurator/setMontageBonus', -300)
      } else {
        store.dispatch('configurator/setMontageBonus', 0)
      }
    }
    return {
      bonus,
      bonusActive,
      type,
      optionsMapGroup,
      total,
      step,
      checkOption,
      checkBonus
    }
  }
}
</script>
